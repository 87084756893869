import { createRoot } from "react-dom/client";
import KcApp, { defaultKcProps, getKcContext } from "keycloakify";

//We assume the file contains: ".my-class { color: red; }"
import "./index.css";

const { kcContext } = getKcContext();

if( kcContext === undefined ){
    throw new Error(
        "This app is a Keycloak theme" +
        "It isn't meant to be deployed outside of Keycloak"
    );
}

createRoot(document.getElementById("root")!).render(
    <KcApp
        kcContext={kcContext}
        {...{
			...defaultKcProps,
			"kcLogoLink": "https://www.softleader.com.tw",
			"kcFormCardClass": [...defaultKcProps.kcFormCardClass, "sl-font"],
			"kcHeaderWrapperClass": [...defaultKcProps.kcHeaderWrapperClass, "sl-font", "sl-color"],
			"kcContentWrapperClass":  [...defaultKcProps.kcContentWrapperClass, "sl-font"],
			"kcHtmlClass": "sl-bg"
		}}
    />
);
